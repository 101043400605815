/*
 START: Style overides for React-Dates
 */
.DateRangePickerInput,
.DateInput {
  display: flex;
}

.DateInput_input {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 7px 7px 5px;
}

.DateRangePickerInput_arrow + .DateInput .DateInput_input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.DateInput {
  border-radius: 0.25rem;
}

.DateRangePickerInput_arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.CalendarDay__selected_span {
  background: #44bb98;
  color: white;
  border: 1px solid '#44BB98';
}

.CalendarDay__selected {
  background: #44bb98;
  color: white;
}

.CalendarDay__selected:hover {
  background: #b4eddc;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #daf5ed;
}

.DateRangePicker_picker,
.DateInput_fang.DateInput_fang_1 {
  margin-top: -10px;
}

.DateRangePickerInput__withBorder {
  border-radius: 0.25rem;
}

/*
 END: Style overides for React-Dates
 */

/*
  START: data management view styles
*/

.data-management .aside {
  height: 310px;
}

/*
  START: data management view styles
*/
